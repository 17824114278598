import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import SelectLanguage from './SelectLanguage';

const Header = () => {
  const { t } = useTranslation();
  return (
    <header className="header">
      <div className="header__body">
        <div className="header__body__left">
          <SelectLanguage />
          <div className="header-claim" dangerouslySetInnerHTML={{ __html: t('header.claim') }}></div>
        </div>
        <div className="header__body__right">
          <div className="header-contact">
            <a
              href="mailto:info@nextweb.capital"
              target="_blank"
              rel="noreferrer"
              dangerouslySetInnerHTML={{ __html: t('header.contact') }}
            ></a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
