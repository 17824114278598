import React from 'react';

import Header from './Header';

export default function Layout(props) {
  return (
    <>
      <main id="main" className={props.isScrolled ? 'main isScrolled' : 'main'}>
        <Header />
        {props.children}
      </main>
    </>
  );
}
