import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { Link } from '../default/Link';

const SelectLanguage = () => {
  const { languages, originalPath } = useI18next();

  return (
    <ul className="header-lang">
      {languages.map(lang => {
        return (
          <li key={lang} className={lang}>
            <Link to={originalPath} language={lang}>
              {lang === 'en' ? 'English' : '日本語'}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SelectLanguage;
